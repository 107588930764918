import { Component, Input, ElementRef, OnChanges } from '@angular/core';
import { LoaderService } from './loader.service';

@Component({
    selector: 'app-loader',
    templateUrl: './loader.component.html',
    styleUrls: ['./loader.component.scss']
})
export class LoaderComponent implements OnChanges {

    @Input() message: string;

    constructor(
        private hostElement: ElementRef,
        private loaderService: LoaderService,
    ) { }

    ngOnChanges(changes) {
        if (!changes.message) {
            return;
        }

        if (changes.message.currentValue === this.loaderService.empty) {
            this.hostElement.nativeElement.classList.add('hidden');
        } else {
            this.message = `${changes.message.currentValue}...`;
            this.hostElement.nativeElement.classList = [];
            if (changes.message.currentValue === this.loaderService.saving) {
                this.hostElement.nativeElement.classList.add('light-opacity');
            }
        }
    }
}
