import { Injectable, ComponentFactoryResolver, ApplicationRef, Injector, EmbeddedViewRef, ComponentRef, Type } from '@angular/core';

import { BladeConfig } from './blade-config';
import { BladeInjector } from './blade-injector';
import { BladeRef } from './blade-ref';
import { BladeComponent } from './blade.component';

declare var $: any;

@Injectable({
    providedIn: 'root'
})

export class BladeService {

    index = 0;

    // array that contains all the blades
    bladeComponentRef: ComponentRef<BladeComponent>[] = [];

    constructor(
        private componentFactoryResolver: ComponentFactoryResolver,
        private appRef: ApplicationRef,
        private injector: Injector
    ) { }

    // used to create a blade
    appendDialogComponentToBody(config: BladeConfig) {
        const arrayIndex = this.index;

        this.index += 1;

        config.index = this.index;

        // use this map to inject into the child so the config is accessible
        const map = new WeakMap();
        map.set(BladeConfig, config);

        // use this bladeRef to notify the parent that the child/ / blade is closed
        const bladeRef = new BladeRef();
        map.set(BladeRef, bladeRef);

        // subscribe to the close method and remove all the blades from a given index
        const sub = bladeRef.result.subscribe(
            () => {
                // close the dialog
                this.closeToIndex(arrayIndex);
                sub.unsubscribe();
            },
            () => {
                // close the dialog
                this.closeToIndex(arrayIndex);
                sub.unsubscribe();
            });

        // create a blade, init the dep inj, add some classes and add the blade to the dom & the blade array
        const componentFactory = this.componentFactoryResolver.resolveComponentFactory(BladeComponent);
        const componentRef = componentFactory.create(new BladeInjector(this.injector, map));
        this.appRef.attachView(componentRef.hostView);

        const blade = (componentRef.hostView as EmbeddedViewRef<any>).rootNodes[0] as HTMLElement;

        blade.classList.add('column');
        blade.classList.add(`column-${config.columns}`);

        $('.fake-column').remove();
        const margin = document.createElement('div');
        margin.classList.add('fake-column');
        margin.classList.add('column');

        document.getElementById('wrapper').appendChild(blade);
        document.getElementById('wrapper').appendChild(margin);

        this.bladeComponentRef.push(componentRef);

        // return the blade ref so that the parent can get notified when the child closes the blade
        return bladeRef;
    }

    // remove a blade from the dom & the array
    private removeDialogComponentFromBody(index: number) {
        // remove the blade from the dom
        this.appRef.detachView(this.bladeComponentRef[index].hostView);
        // call the destroy method to free up memory & resources
        this.bladeComponentRef[index].destroy();
        // remove the blade from the blade array
        this.bladeComponentRef.splice(index, 1);
        // set the index
        this.index -= 1;
    }

    public open(index: number, componentType: Type<any>, config: BladeConfig) {

        // Close other blades if we open a blade from a blade earlier in the array
        this.closeToIndex(index);

        // Create the blade and return the bladeRef so that the parent can get notified when the child closes the blade
        const bladeRef = this.appendDialogComponentToBody(config);

        // set the component type of the blade so it will know which component to create
        this.bladeComponentRef[this.index - 1].instance.childComponentType = componentType;
        this.bladeComponentRef[this.index - 1].instance.title = config.title;
        this.bladeComponentRef[this.index - 1].instance.index = config.index;

        // Scroll to the right side of the view
        $('.wrapper').scrollLeft($('.wrapper')[0].scrollWidth);

        return bladeRef;
    }

    public closeToIndex(index: number) {
        // remove blades that have a bigger index that the one we clicked on
        if (this.bladeComponentRef.length - 1 >= index) {

            // tslint:disable-next-line: no-increment-decrement
            for (let i = this.bladeComponentRef.length; i > index; i--) {
                this.removeDialogComponentFromBody(i - 1);
            }
        }
    }
}
