export class ComplaintDetail {
    public description: string;
    public citationDocumentId: string;
    public id: string;
    public date: Date;
    public subject: string;
    public dateFinished: Date;
    public status: number;
    public departmentId: string;
    public remarks: string;
    public createdBy: string;
    public agendaTypeId: string;
}
