import { Component, Input, OnChanges, ElementRef } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';
import { ValidationService } from './validation.service';

@Component({
    selector: 'app-validation',
    templateUrl: './validation.component.html',
    styleUrls: ['./validation.component.scss']
})
export class ValidationComponent implements OnChanges {

    @Input() validationError: HttpErrorResponse;

    errors: string[] = [];

    constructor(
        private hostElement: ElementRef,
        private validationService: ValidationService,
    ) { }


    ngOnChanges(changes) {
        if (changes.validationError.currentValue !== null) {
            this.errors = this.validationService.generateValidationError(this.validationError);
            this.scrollToTop();
        } else {
            this.errors = null;
        }
    }

    private scrollToTop() {
        this.hostElement.nativeElement.parentElement.scrollTop = 0;
    }

}
