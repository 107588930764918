import { Injectable } from '@angular/core';
import { Observable, Observer } from 'rxjs';

import 'devextreme-intl';
import { locale, loadMessages } from 'devextreme/localization';
import * as de from 'devextreme/localization/messages/de.json';
import * as fr from 'devextreme/localization/messages/fr.json';
import * as nl from 'devextreme/localization/messages/nl.json';
import * as en from 'devextreme/localization/messages/en.json';

import { UserService } from '../user/user.service';
import { HttpService } from '../http/http.service';


@Injectable({
    providedIn: 'root'
})
export class TranslationService {

    constructor(
        private http: HttpService,
        private userService: UserService,
    ) { }


    private translations = {};
    private translation;

    initTranslations(): Observable<string> {
        return new Observable((observer: Observer<string>) => {

            const languageCode = this.userService.getLanguageCode();

            // Set localization for devextreme components
            switch (languageCode.toLowerCase()) {
                case 'fr-be':
                case 'fr-fr':
                    loadMessages(fr);
                    locale('fr');
                    break;
                case 'nl-be':
                case 'nl-nl':
                    loadMessages(nl);
                    locale('nl');
                    break;
                case 'en-us':
                    loadMessages(en);
                    locale('en');
                    break;
                case 'de-de':
                    loadMessages(de);
                    locale('de');
                    break;
                default:
                    loadMessages(en);
                    locale('en');
                    break;
            }

            const t = sessionStorage.getItem(languageCode);

            if (!t) {
                // Get via API Call
                this.http.getTranslationApi().subscribe(
                    (result: any) => {
                        result.forEach((t) => {
                            this.translations[t.name.toLowerCase()] = t.value;
                        });
                        sessionStorage.setItem(languageCode, JSON.stringify(this.translations));
                        observer.next('');
                        observer.complete();
                        return;
                    }
                );
            } else {
                this.translations = JSON.parse(t);
                observer.next('');
                observer.complete();
                return;
            }
        });

    }

    get(key: string) {
        this.translation = this.translations[key.toLowerCase()];

        if (!this.translation) {
            console.warn(`Translation for \'${key}\' not found`);
            return key;
        }

        return this.translation;
    }
}
