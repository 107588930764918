import { Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root'
})
export class PermissionService {

    constructor() { }

    private permissions;

    hasPermission(permission: string): boolean {
        if (!this.permissions) {
            this.permissions = sessionStorage.getItem('permissions');
        }

        return this.permissions.includes(permission);
    }
}
