import { Component, OnInit } from '@angular/core';
import { BladeConfig } from '../../core/blade/blade-config';
import { BladeService } from '../../core/blade/blade.service';
import { ComplaintService } from '../shared/complaint.service';
import { SidebarItem } from '../../shared/sidebar/sidebar-item.model';
import { ComplaintsOverviewComponent } from '../overview/complaints-overview.component';
import { PermissionService } from '../../shared/permission/permission.service';
import { TranslationService } from '../../shared/translation/translation.service';
import { TemplatesOverviewComponent } from '../../templates/overview/templates-overview.component';

@Component({
    selector: 'app-complaint-sidebar',
    templateUrl: './complaint-sidebar.component.html',
    styleUrls: ['./complaint-sidebar.component.scss']
})

export class ComplaintSidebarComponent implements OnInit {

    private readonly PermissionManageComplaints = '9dd22672-34d5-447e-93a0-4b5b38458c1e';
    private readonly PermissionReminderApiGeneral = '5e5c6642-9aac-4ff7-bcb4-d0d4dcb601ba';

    constructor(
        private config: BladeConfig,
        private blade: BladeService,
        private complaintService: ComplaintService,
        private permissionService: PermissionService,
        private t: TranslationService,
    ) {    }

    private sidebar: SidebarItem[] = [];

    ngOnInit() {
        const management: SidebarItem = { name: this.t.get('Management'), icon: null, redirecturl: null, component: null, children: [] };
        management.children.push(
            {
                name: this.t.get('Complaints'),
                icon: 'fa fa-user',
                hasPermission: this.permissionService.hasPermission(this.PermissionManageComplaints),
                redirecturl: null,
                component: 'complaints-overview',
                children: []
            },
            {
                name: this.t.get('Templates'),
                icon: 'fa fa-file',
                hasPermission: this.permissionService.hasPermission(this.PermissionReminderApiGeneral),
                redirecturl: null,
                component: 'templates-overview',
                children: []
            }
        );
        this.sidebar.push(management);
    }

    open(c) {
        let component: any;
        let config: BladeConfig;

        switch (c.toLowerCase()) {
            case 'complaints-overview':
                component = ComplaintsOverviewComponent;
                config = this.complaintService.getBladeConfig();
                break;
            case 'templates-overview':
                component = TemplatesOverviewComponent;
                config = this.complaintService.getBladeConfig();
                break;
            default:
                throw 'Component not found';
        }

        this.blade.open(this.config.index, component, config);

    }


}
