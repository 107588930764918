import { Component, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

import { BladeConfig } from '../../core/blade/blade-config';
import { BladeService } from '../../core/blade/blade.service';
import { BladeRef } from '../../core/blade/blade-ref';
import { ComplaintService } from '../shared/complaint.service';
import { ComplaintDetail } from '../shared/complaint-detail.model';
import { ComplaintInGrid } from '../shared/complaint-in-grid.model';
import { ComplaintsApprovalComponent } from '../approval/complaints-approval.component';
import { Lightbox } from 'ngx-lightbox';
import { LightboxImage } from '../../shared/lightbox/lightboxImage';
import { Media } from '../../shared/media/media';
import { AgendaType } from '../shared/agenda-type.enum';
import { ComplaintState } from '../shared/complaint-state.enum';
import { TranslationService } from '../../shared/translation/translation.service';
import { LoaderService } from '../../core/loader/loader.service';

@Component({
    selector: 'app-complaints-detail',
    templateUrl: './complaints-detail.component.html',
    styleUrls: ['./complaints-detail.component.scss']
})

export class ComplaintsDetailComponent implements OnInit {

    constructor(
        private config: BladeConfig,
        private blade: BladeService,
        private bladeRef: BladeRef,
        private modalService: NgbModal,
        private service: ComplaintService,
        private lightbox: Lightbox,
        private t: TranslationService,
        private loaderService: LoaderService,
    ) { }

    loader = this.loaderService.loading;

    complaint: ComplaintDetail = new ComplaintDetail();
    remarks = '';
    detail: any = {};
    images: LightboxImage[] = [];
    attachments: Media[];

    approvedOrRejected = false;
    showNewOwnerActionButtons = false;

    stackOpened = false;

    ngOnInit() {
        this.loadComplaint();
    }

    close() {
        // reload grid on closing complaint
        if (this.approvedOrRejected) {
            this.bladeRef.close(this.complaint.id);
        } else {
            this.bladeRef.dismiss();
        }
    }

    openImage(index: number) {
        this.lightbox.open(this.images, index, { showImageNumberLabel: true });
    }

    reject(content) {
        this.modalService.open(content, { size: 'lg' }).result.then(
            () => {
                this.loader = this.loaderService.saving;
                this.service.putRejectComplaint(this.complaint.departmentId, this.complaint.id, this.complaint.remarks).subscribe(
                    () => {
                        this.approvedOrRejected = true;
                        // rejected, so reload this
                        this.loadComplaint();
                    },
                    () => {

                    }
                );
            },
            () => {
                // dismissed
            }
        );
    }

    approve() {
        this.stackOpened = true;

        const blade = this.blade.open(this.config.index, ComplaintsApprovalComponent, this.service.getBladeConfigApproval(this.t.get('Approve'), this.complaint));

        blade.result.subscribe(
            () => {
                this.approvedOrRejected = true;
                // approved, so reload this detail
                this.loadComplaint();
            },
            () => {
                // dismissed
            }
        ).add(() => {
            // Finally
            this.stackOpened = false;
        });
    }

    loadComplaint() {
        const data: ComplaintInGrid = this.config.data;

        this.service.getDetail(data.departmentId, data.id).subscribe(
            (c: ComplaintDetail) => {
                this.complaint = c;
                if (this.complaint.remarks === undefined) {
                    this.complaint.remarks = '';
                }
                // show action buttons if complaint is open and applicable for the new owner flow
                if (this.complaint.status === ComplaintState.Open && (this.complaint.agendaTypeId === AgendaType.AGTP_RAPO_1_2_NotOwner || this.complaint.agendaTypeId === AgendaType.AGTP_RAPO_1_3_Transferred_Vehicle)) {
                    this.showNewOwnerActionButtons = true;
                } else {
                    this.showNewOwnerActionButtons = false;
                }

                this.service.getCitationDetail(data.departmentId, this.complaint.citationDocumentId).subscribe(
                    (detail) => {
                        this.detail = detail;
                        this.loader = this.loaderService.empty;
                    }
                );

                this.service.getPictures(this.complaint.departmentId, this.complaint.citationDocumentId).subscribe(
                    (media: Media[]) => {
                        this.images = [];
                        media.forEach((m) => {
                            this.images.push(new LightboxImage(m.url));
                        });
                    }
                );

                this.service.getAttachments(this.complaint.departmentId, this.complaint.citationDocumentId, this.complaint.id).subscribe(
                    (attachments: Media[]) => {
                        const re = /(?:\.([^.]+))?$/;
                        attachments.forEach((attachment) => {
                            const extension = re.exec(attachment.name)[1];
                            attachment.extension = extension ? extension.toLowerCase() : '-';
                        });
                        this.attachments = attachments;
                    }
                );
            });
    }
}
