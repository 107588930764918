export class ComplaintNewOwner {
    public title: string;
    public firstName: string;
    public lastName: string;
    public street: string;
    public streetNumber: string;
    public postalCode: string;
    public city: string;
    public country: string;

    constructor() { }
}
