import { Component, OnInit } from '@angular/core';
import { BladeConfig } from '../../core/blade/blade-config';
import { BladeService } from '../../core/blade/blade.service';
import { EnvironmentService } from '../../shared/environment/environment.service';
import { LanguagesService } from '../languages/shared/languages.service';
import { SidebarItem } from '../../shared/sidebar/sidebar-item.model';
import { LanguagesOverviewComponent } from '../languages/overview/languages-overview.component';

@Component({
    selector: 'app-management-sidebar',
    templateUrl: './management-sidebar.component.html',
    styleUrls: ['./management-sidebar.component.scss']
})

export class ManagementSidebarComponent implements OnInit {

    constructor(
        private config: BladeConfig,
        private blade: BladeService,
        private environmentService: EnvironmentService,
        private languagesService: LanguagesService,
    ) { }

    sidebar: SidebarItem[] = [];

    ngOnInit() {
        const languages: SidebarItem = { name: 'Talen', icon: null, redirecturl: null, component: null, children: [] };
        languages.children.push(
            { name: 'Talen', icon: 'fa fa-flag', redirecturl: `${this.environmentService.portalUrl}Language` , component: null, children: [] }, // `${this.environmentService.portalUrl}Language`
            { name: 'Vertalingen', icon: 'fa fa-font', redirecturl: `${this.environmentService.portalUrl}Translation`, component: null, children: [] }
        );

        const deploy: SidebarItem = { name: 'Deployment variabelen', icon: null, redirecturl: null, component: null, children: [] };
        deploy.children.push(
            { name: 'Applicaties', icon: 'fa fa-cloud', redirecturl: `${this.environmentService.portalUrl}Application`, component: null, children: [] },
            { name: 'Omgevingen', icon: 'fa fa-globe', redirecturl: `${this.environmentService.portalUrl}Environment`, component: null, children: [] },
            { name: 'Afdelingen', icon: 'fa fa-home', redirecturl: `${this.environmentService.portalUrl}Department`, component: null, children: [] },

        );

        const settings: SidebarItem = { name: 'Instellingen', icon: null, redirecturl: null, component: null, children: [] };
        settings.children.push(
            { name: 'Instellingen', icon: 'fa fa-lock', redirecturl: `${this.environmentService.portalUrl}SettingKey`, component: null, children: [] },
            { name: 'Waarde types', icon: 'fa fa-asterisk', redirecturl: `${this.environmentService.portalUrl}ValueType`, component: null, children: [] },
            { name: 'Audit types', icon: 'fa fa-circle-o', redirecturl: `${this.environmentService.portalUrl}AuditType`, component: null, children: [] },
        );

        const config: SidebarItem = { name: 'Configuraties', icon: null, redirecturl: null, component: null, children: [] };
        config.children.push(
            { name: 'Configuraties', icon: 'fa fa-wrench', redirecturl: `${this.environmentService.portalUrl}Configuration`, component: null, children: [] }
        );

        const events: SidebarItem = { name: 'Events', icon: null, redirecturl: null, component: null, children: [] };
        events.children.push(
            { name: 'Triggers', icon: 'fa fa-bolt', redirecturl: `${this.environmentService.portalUrl}Trigger`, component: null, children: [] },
            { name: 'Acties', icon: 'fa fa-bell', redirecturl: `${this.environmentService.portalUrl}EventAction`, component: null, children: [] },
            { name: 'Events', icon: 'fa fa-bullhorn', redirecturl: `${this.environmentService.portalUrl}Event`, component: null, children: [] },
        );

        const category: SidebarItem = { name: 'Categorie', icon: null, redirecturl: null, component: null, children: [] };
        category.children.push(
            { name: 'Categorie', icon: 'fa fa-tags', redirecturl: `${this.environmentService.portalUrl}Category`, component: null, children: [] },
        );

        const logs: SidebarItem = { name: 'Logs', icon: null, redirecturl: null, component: null, children: [] };
        logs.children.push(
            { name: 'Logs bekijken', icon: 'fa fa-th-list', redirecturl: `${this.environmentService.portalUrl}Logging?timeOffset=-120`, component: null, children: [] },
            { name: 'Factureerbare aanvragen', icon: 'fa fa-eur', redirecturl: `${this.environmentService.portalUrl}BillableRequests`, component: null, children: [] },
        );

        this.sidebar.push(languages);
        this.sidebar.push(deploy);
        this.sidebar.push(settings);
        this.sidebar.push(config);
        this.sidebar.push(events);
        this.sidebar.push(category);
        this.sidebar.push(logs);
    }

    open(c, r) {

        if (r !== null && c === null) {
            window.location.href = r;
        } else {
            let component: any;
            let config: BladeConfig;

            switch (c.toLowerCase()) {
                case 'languages-overview':
                    component = LanguagesOverviewComponent;
                    config = this.languagesService.getBladeConfig();
                    break;
                default:
                    throw 'Component not found';
            }

            this.blade.open(this.config.index, component, config);
        }
    }

}

