import { Injectable } from '@angular/core';
import { TranslationService } from '../../shared/translation/translation.service';

@Injectable({
    providedIn: 'root'
})
export class LoaderService {

    public empty = '';
    public loading = this.translationService.get('Loading');
    public saving = this.translationService.get('Saving');

    constructor(
        private translationService: TranslationService,
    ) { }
}
