export class LightboxImage {

    public src: string;
    public caption: string;
    public thumb: string;

    constructor(src: string) {
        this.src = src;
    }
}
