import { Component, OnInit } from '@angular/core';
import { BladeService } from './core/blade/blade.service';
import { SidebarService } from './shared/sidebar/sidebar.service';
import { ComplaintSidebarComponent } from './complaints/sidebar/complaint-sidebar.component';
import { TranslationService } from './shared/translation/translation.service';


@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
    constructor(
        private blade: BladeService,
        private sidebarService: SidebarService,
        private t: TranslationService,
    ) {

    }

    ngOnInit() {
        this.blade.open(0, ComplaintSidebarComponent, this.sidebarService.getBladeConfig(this.t.get('Complaints')));
    }
}
