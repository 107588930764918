import { Injectable } from '@angular/core';
import { BladeConfig } from '../../core/blade/blade-config';

@Injectable({
    providedIn: 'root'
})
export class SidebarService {

    getBladeConfig(name: string): BladeConfig {
        return new BladeConfig(2, name);
    }
}
