import { Component } from '@angular/core';
import { BladeService } from '../core/blade/blade.service';
import { SidebarService } from '../shared/sidebar/sidebar.service';
import { EnvironmentService } from '../shared/environment/environment.service';
import { BladeConfig } from '../core/blade/blade-config';
import { ComplaintSidebarComponent } from '../complaints/sidebar/complaint-sidebar.component';
import { ManagementSidebarComponent } from '../management/sidebar/management-sidebar.component';
import { TranslationService } from '../shared/translation/translation.service';


@Component({
    selector: 'app-sidebar',
    templateUrl: './sidebar.component.html',
    styleUrls: ['./sidebar.component.scss']
})
export class SidebarComponent {

    constructor(
        private blade: BladeService,
        private sidebarService: SidebarService,
        private environmentService: EnvironmentService,
        private t: TranslationService,
    ) { }

    redirect(a) {
        let action = '';
        switch (a) {
            case 'parking':
                action = 'ParkingMonitor';
                break;
            case 'people':
                action = 'Users';
                break;
            case 'settings':
                action = 'SettingsPortal';
                break;
            case 'team':
                action = 'Configuration';
        }
        window.location.href = this.environmentService.portalUrl + action;
    }

    open(componentName) {

        let config: BladeConfig;
        let component: any;

        switch (componentName) {
            case 'complaints':
                config = this.sidebarService.getBladeConfig('Complaints');
                component = ComplaintSidebarComponent;
                break;
            case 'team':
                config = this.sidebarService.getBladeConfig('Management');
                component = ManagementSidebarComponent;
                break;
            default:
                console.error('Component not found');
                break;
        }

        this.blade.open(0, component, config);
    }

}
