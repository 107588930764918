import { Component, OnInit, ViewChild } from '@angular/core';
import CustomStore from 'devextreme/data/custom_store';
import { DxDataGridComponent } from 'devextreme-angular';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

import { BladeConfig } from '../../../core/blade/blade-config';
import { BladeService } from '../../../core/blade/blade.service';
import { BladeRef } from '../../../core/blade/blade-ref';

import { LanguagesService } from '../shared/languages.service';
import { Language } from '../shared/language.model';
import { LanguageCode } from '../shared/languagecode.model';
import { LanguagesEditComponent } from '../edit/languages-edit.component';

import * as $ from 'jquery';

@Component({
    selector: 'app-languages-overview',
    templateUrl: './languages-overview.component.html',
    styleUrls: ['./languages-overview.component.scss']
})

export class LanguagesOverviewComponent implements OnInit {

    // to catch events in the grid
    @ViewChild('grid', { static: true }) grid: DxDataGridComponent;


    constructor(
        private config: BladeConfig,
        private blade: BladeService,
        private bladeRef: BladeRef,
        private modalService: NgbModal,
        private languagesService: LanguagesService,
    ) { }

    langcodes: LanguageCode[];
    alllanguages: Language[] = [];
    languages: Language[] = [];
    gridDataSource: any;
    gridAmount: number;
    gridColumns: any;
    languageToDelete: Language;

    ngOnInit() {
        this.languagesService.getLangcodes().subscribe((codes: LanguageCode[]) => { this.langcodes = codes; });
        this.gridColumns = [
            { dataField: 'name', caption: 'Naam', dataType: 'string', },
            { dataField: 'code', caption: 'Code', dataType: 'string', },
            { width: 100, cellTemplate: 'actions', allowfiltering: false, allowSorting: false },

        ];
        const self = this;

        this.gridDataSource = new CustomStore({
            load(loadOptions: any) {
                return self.languagesService.getLanguages().toPromise().then(
                    (langresult: Language[]) => {
                        self.alllanguages = langresult;
                        self.languages = langresult.filter(o1 => !self.langcodes.some(o2 => o1.code === o2.code));
                        self.gridAmount = langresult.length;
                        return { data: langresult };
                    });
            }
        });

    }


    // Used for calculating the height of the grid
    gridInitialized(e) {
        const filterHeight = $(e.element).parentsUntil('.content').parent().find('.total-gridamount').outerHeight(true);
        // calc --> 100% - height of the filter - margin top of the grid
        $(e.element).parentsUntil('.content').parent().find('.grid').css('height', `calc(100% - ${filterHeight}px)`);
    }

    showBlade(languageid) {
        const editBlade = this.blade.open(this.config.index, LanguagesEditComponent, this.languagesService.getBladeConfigDetail('Edit', languageid));
        editBlade.result.subscribe(
            (result) => {
                this.grid.instance.refresh();
            },
            () => {
                // dismissed.
            });
    }

    deleteRow(modal, language) {
        this.languageToDelete = language;

        this.modalService.open(modal, { size: 'lg' }).result.then(
            (result) => {
                // remove row with given id
                this.languagesService.removeLanguage(language.id).subscribe(
                    () => {
                        // refresh the grid
                        this.grid.instance.refresh();
                    });
            },
            () => {
                // dismissed.
            }
        );
    }
}
