import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http';
import { from, Observable } from 'rxjs';
import { mergeMap } from 'rxjs/operators';
import { AuthService } from './auth.service';
import { Token } from './token.model';

@Injectable()
export class TokenInterceptor implements HttpInterceptor {

    constructor(
        private auth: AuthService
    ) { }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

        return from(this.auth.getTokens()).pipe(mergeMap((token: Token) => {

            if (token) {
                // clone and modify the request
                // tslint:disable-next-line: no-parameter-reassignment
                request = request.clone({
                    setHeaders: {
                        Authorization: `Bearer ${token.accesstoken}`
                    }
                });
            }

            return next.handle(request);
        }));
    }
}
