import { Component, OnInit, ViewChild } from '@angular/core';
import { BladeConfig } from '../../../core/blade/blade-config';
import { BladeService } from '../../../core/blade/blade.service';
import { BladeRef } from '../../../core/blade/blade-ref';
import { Language } from '../shared/language.model';
import ArrayStore from 'devextreme/data/array_store';
import { LanguagesService } from '../shared/languages.service';
import { DxSelectBoxComponent, DxCheckBoxComponent } from 'devextreme-angular';
import { LanguageCode } from '../shared/languagecode.model';


@Component({
    selector: 'app-languages-edit',
    templateUrl: './languages-edit.component.html',
    styleUrls: ['./languages-edit.component.scss']
})
export class LanguagesEditComponent implements OnInit {

    @ViewChild('langcode', { static: true }) selectedcode: DxSelectBoxComponent;
    language: Language;
    codes: any;
    codeDataSource: any;
    copyAllLang: boolean;

    constructor(
        private config: BladeConfig,
        private blade: BladeService,
        private bladeRef: BladeRef,
        private languagesService: LanguagesService,
    ) { }

    ngOnInit() {
        const id: string = this.config.data;
        this.copyAllLang = false;
        this.languagesService.getLangcodes().subscribe((codes: LanguageCode[]) => { this.codes = codes; });
        this.codeDataSource = new ArrayStore({
            data: this.codes,
            key: 'id'
        });

        this.languagesService.getLanguage(id).subscribe((lang: Language) => {
            this.language = lang;
            this.copyAllLang = lang.copytoalllanguages;
        });
    }
    close() {
        this.bladeRef.dismiss();
    }
    saveLanguage() {
        // validation check before closing modal and sending object Language
        if (this.language.name) {
            this.language.code = this.selectedcode.text;
            this.language.copytoalllanguages = this.copyAllLang;
            this.languagesService.saveLanguage(this.language).subscribe(() => { });
        }
        this.bladeRef.close(this.language);
    }
}
