import { Injectable } from '@angular/core';
import { forkJoin, Observable, Observer } from 'rxjs';
import { HttpService } from '../../shared/http/http.service';
import { EnvironmentService } from '../../shared/environment/environment.service';
import { BladeConfig } from '../../core/blade/blade-config';
import { ComplaintState } from './complaint-state.model';
import { ComplaintType } from './complaint-type.model';
import { ComplaintSearch } from './complaint-search.model';
import { ComplaintInGrid } from './complaint-in-grid.model';
import { ComplaintDetail } from './complaint-detail.model';
import { Media } from '../../shared/media/media';
import { ComplaintNewOwner } from './complaint-new-owner.model';
import { TranslationService } from '../../shared/translation/translation.service';
import { UserService } from '../../shared/user/user.service';

@Injectable({
    providedIn: 'root'
})

export class ComplaintService {

    constructor(
        private http: HttpService,
        private environment: EnvironmentService,
        private t: TranslationService,
        private userService: UserService,
    ) { }

    getBladeConfig(): BladeConfig {
        return new BladeConfig(11, this.t.get('Complaints'));
    }

    getBladeConfigDetail(name: string, data: any): BladeConfig {
        return new BladeConfig(9, name, data);
    }

    getBladeConfigApproval(name: string, data: any): BladeConfig {
        return new BladeConfig(4, name, data);
    }

    getStates(): ComplaintState[] {
        return [{ state: this.t.get('Open'), value: 1 }, { state: this.t.get('Closed'), value: 2 }];
    }

    getTypes(): ComplaintType[] {
        return [
            { id: '00000000-0000-0000-0001-000000010000', text: this.t.get('FR_Rapo_Type_1_1') },
            { id: '00000000-0000-0000-0001-000000020000', text: this.t.get('FR_Rapo_Type_1_2') },
            { id: '00000000-0000-0000-0001-000000030000', text: this.t.get('FR_Rapo_Type_1_3') },
            { id: '00000000-0000-0000-0001-000000040000', text: this.t.get('FR_Rapo_Type_1_4') },
            { id: '00000000-0000-0000-0002-000000010000', text: this.t.get('FR_Rapo_Type_2_1') },
            { id: '00000000-0000-0000-0002-000000020000', text: this.t.get('FR_Rapo_Type_2_2') },
            { id: '00000000-0000-0000-0002-000000030000', text: this.t.get('FR_Rapo_Type_2_3') },
            { id: '00000000-0000-0000-0002-000000040000', text: this.t.get('FR_Rapo_Type_2_4') },
            { id: '00000000-0000-0000-0003-000000010000', text: this.t.get('FR_Rapo_Type_3_1') },
            { id: '00000000-0000-0000-0003-000000020000', text: this.t.get('FR_Rapo_Type_3_2') },
            { id: '00000000-0000-0000-0003-000000030000', text: this.t.get('FR_Rapo_Type_3_3') },
            { id: '00000000-0000-0000-0003-000000040000', text: this.t.get('FR_Rapo_Type_3_4') },
            { id: '00000000-0000-0000-0004-000000010000', text: this.t.get('FR_Rapo_Type_4_1') },
            { id: '00000000-0000-0000-0004-000000020000', text: this.t.get('FR_Rapo_Type_4_2') },
            { id: '00000000-0000-0000-0004-000000030000', text: this.t.get('FR_Rapo_Type_4_3') },
            { id: '00000000-0000-0000-0004-000000040000', text: this.t.get('FR_Rapo_Type_4_4') },
        ];
    }

    getByDepartments(departments: string[], searchObject: ComplaintSearch): Observable<ComplaintInGrid[]> {

        const self = this;
        return new Observable((observer: Observer<ComplaintInGrid[]>) => {
            const httpCalls = [];
            let data: ComplaintInGrid[] = [];

            departments.forEach((department) => {
                httpCalls.push(this.http.postComplaintApi<ComplaintInGrid[]>(department, 'complaints/overview', searchObject));
            });

            forkJoin(httpCalls).subscribe((results: ComplaintInGrid[]) => {
                results.forEach((result) => {
                    data = data.concat(result);
                });

                observer.next(data);
                observer.complete();
            });
        });
    }

    getDetail(departmentId: string, complaintsId: string): Observable<ComplaintDetail> {
        return this.http.getComplaintApi(departmentId, `complaints/${complaintsId}`);
    }

    getCitationDetail(departmentsId: string, complaintsId: string): Observable<any> {
        return this.http.getSfpsApi(`${departmentsId}/fines/${this.environment.sfpsApiVersion}/${complaintsId}`);
    }

    getPictures(departmentsId: string, documentsId: string): Observable<Media[]> {
        return this.http.getDocApi(`${departmentsId}/documents/${documentsId}/pictures`);
    }

    getAttachments(departmentsId: string, documentsId: string, complaintsId: string): Observable<Media[]> {
        return this.http.getDocApi(`${departmentsId}/documents/${documentsId}/history/attachments/${complaintsId}`);
    }

    putRejectComplaint(departmentId: string, complaintsId: string, remarks: string) {
        const putObject = {
            remarks
        };
        return this.http.putComplaintApi(departmentId, `complaints/${complaintsId}/reject`, putObject);
    }

    putApproveComplaint(departmentId: string, complaintsId: string, citationDocumentId: string, agendaTypeId: string, newOwner: ComplaintNewOwner) {
        const putObject = {
            citationDocumentId,
            agendaTypeId,
            newOwner,
        };
        return this.http.putComplaintApi(departmentId, `complaints/${complaintsId}/approve`, putObject);
    }

    getCountries(departmentId: string): Observable<string[]> {
        return new Observable((observer) => {
            this.http.getComplaintApi(departmentId, `static-data/countries`).subscribe(
                (result) => {
                    const data = this.mapCountries(result);
                    observer.next(data);
                    observer.complete();
                }
            );
        });
    }

    private mapCountries(data): string[] {
        const countries: string[] = [];
        const languageCode = this.userService.getLanguageCode();
        let translation;
        let translationValue;
        data.forEach((d) => {
            translation = d.translations.find((t) => {
                return t.language.toLowerCase() === languageCode.toLowerCase();
            });

            if (translation === undefined) {
                if (d.translations.length > 0) {
                    translationValue = d.translations[0].value;
                } else {
                    translation = '';
                }
            } else {
                translationValue = translation.value;
            }

            countries.push(translationValue);
        });

        return countries.sort();
    }
}
