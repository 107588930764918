import { Injectable } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';

@Injectable({
    providedIn: 'root'
})
export class ValidationService {

    constructor() { }

    public generateValidationError(error: HttpErrorResponse): string[] {
        if (error.status !== 400) {
            return [];
        }

        const model: string[] = [];
        error.error.errors.forEach((e) => {
            model.push(e.message);
        });

        return model;
    }
}
