import { Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root'
})

export class EnvironmentService {

    applicationId = '28ad59c5-18df-4afc-a3ab-48c8fe9d7340';

    identityUrl = 'id';
    portalUrl = 'mportal';
    manApiUrl = 'manapi';
    complaintApiUrl = 'complaintapi';
    serveurFpsApiUrl = 'serveurfps';
    docApiUrl = 'docapi';
    staticDataApiUrl = 'staticapi';
    translationApiUrl = 'translationapi';
    reminderApiUrl = 'reminderapi';

    complaintApiVersion = 'v1';
    sfpsApiVersion = 'v1';
    staticDataApiVersion = 'v1';
    translationApiVersion = 'v1';
    reminderApiVersion = 'v1';

    init() {
        const host = window.location.origin;
        const split = host.split('.');

        if (split.indexOf('q2c') > -1) {
            // prod
            this.fillUrl(`.${split[split.length - 2]}.${split[split.length - 1]}`);
        } else if (split.indexOf('genetecdev') > -1) {
            // test or dev
            const subdomain = split[0].split('-');
            this.fillUrl(`-${subdomain[subdomain.length - 1]}.${split[split.length - 2]}.${split[split.length - 1]}`);
        } else {
            // localhost
            this.fillUrl('-dev1.genetecdev.net');
        }
    }

    private fillUrl(suffixUrl: string) {

        let suffix = suffixUrl;

        if (!suffix.endsWith('/')) {
            suffix += '/';
        }

        this.identityUrl = `https://${this.identityUrl + suffix}`;
        this.portalUrl = `https://${this.portalUrl + suffix}`;
        this.manApiUrl = `https://${this.manApiUrl + suffix}`;
        this.complaintApiUrl = `https://${this.complaintApiUrl + suffix}`;
        this.serveurFpsApiUrl = `https://${this.serveurFpsApiUrl + suffix}`;
        this.docApiUrl = `https://${this.docApiUrl + suffix}`;
        this.staticDataApiUrl = `https://${this.staticDataApiUrl + suffix}`;
        this.translationApiUrl = `https://${this.translationApiUrl + suffix}`;
        this.reminderApiUrl = `https://${this.reminderApiUrl + suffix}`;
    }
}
