import { Observable, Subject } from 'rxjs';

// used to notify the parent when the child blade is closed
export class BladeRef {

    private readonly resultSubject = new Subject<any>();
    result: Observable<any> = this.resultSubject.asObservable();

    close(result?: any) {
        this.resultSubject.next(result);
    }

    dismiss(result?: any) {
        this.resultSubject.error(result);
    }
}
