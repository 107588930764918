import { Component, OnInit } from '@angular/core';
import { debounceTime } from 'rxjs/operators';
import { ErrorService } from './error.service';

@Component({
    selector: 'app-error',
    templateUrl: './error.component.html',
    styleUrls: ['./error.component.scss']
})
export class ErrorComponent implements OnInit {

    error: string;

    constructor(
        private service: ErrorService,
    ) { }

    ngOnInit() {
        this.service.error.subscribe((error) => {
            this.error = error;
        });

        this.service.error.pipe(
            debounceTime(5000)
        ).subscribe(() => { this.error = null; });
    }
}
