import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpService } from '../../shared/http/http.service';
import { Department } from './department.model';


@Injectable({
    providedIn: 'root'
})
export class ManagementService {

    constructor(
        private http: HttpService,
    ) { }

    getDepartments(): Observable<Department[]> {
        return this.http.getManApi<Department[]>('api/departments/claims');
    }
}
