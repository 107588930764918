import { Component, OnInit } from '@angular/core';
import CustomStore from 'devextreme/data/custom_store';
import { BladeRef } from '../../core/blade/blade-ref';
import { ComplaintService } from '../shared/complaint.service';
import { ComplaintDetail } from '../shared/complaint-detail.model';
import { BladeConfig } from '../../core/blade/blade-config';
import { ComplaintNewOwner } from '../shared/complaint-new-owner.model';
import { HttpErrorResponse } from '@angular/common/http';
import { LoaderService } from '../../core/loader/loader.service';
import { TranslationService } from '../../shared/translation/translation.service';
import { StaticDataService } from '../../shared/static-data/static-data.service';

@Component({
    selector: 'app-complaints-approval',
    templateUrl: './complaints-approval.component.html',
    styleUrls: ['./complaints-approval.component.scss']
})

export class ComplaintsApprovalComponent implements OnInit {

    constructor(
        private config: BladeConfig,
        private bladeRef: BladeRef,
        private complaintService: ComplaintService,
        private loaderService: LoaderService,
        private t: TranslationService,
    ) { }

    validationError: HttpErrorResponse = null;
    complaint: ComplaintDetail;
    newOwner: ComplaintNewOwner = new ComplaintNewOwner();
    disableApprove = false;
    loader = this.loaderService.loading;

    selectedCountry: string;
    countries: string[];

    ngOnInit() {
        this.complaint = this.config.data;
        this.complaintService.getCountries(this.complaint.departmentId).subscribe(
            (result) => {
                this.countries = result;
                this.loader = this.loaderService.empty;
            }
        );
    }

    approve() {
        this.validationError = null;
        this.loader = this.loaderService.saving;
        this.disableApprove = true;

        this.newOwner.country = this.selectedCountry;

        this.complaintService.putApproveComplaint(this.complaint.departmentId, this.complaint.id, this.complaint.citationDocumentId, this.complaint.agendaTypeId, this.newOwner).subscribe(
            () => {
                this.bladeRef.close();
                this.disableApprove = false;
            },
            (error) => {
                if (error.status === 400) {
                    error.error.errors.forEach((e) => {
                        if (e.code === '1013') {
                            e.message = this.t.get('ComplaintApproveFailedCheckFineInBackOffice');
                        } else {
                            e.message = this.t.get('GenericErrorLogged');
                        }
                    });
                }

                this.validationError = error;
                this.disableApprove = false;
            }
        ).add(() => {
            // Finally
            this.loader = this.loaderService.empty;
        });
    }

    close() {
        this.bladeRef.dismiss();
    }

}
