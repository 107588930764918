export enum AgendaType {
    AGTP_RAPO_1_1_NoVehicle = '00000000-0000-0000-0001-000000010000',
    AGTP_RAPO_1_2_NotOwner = '00000000-0000-0000-0001-000000020000',
    AGTP_RAPO_1_3_Transferred_Vehicle = '00000000-0000-0000-0001-000000030000',
    AGTP_RAPO_1_4_Usurpation = '00000000-0000-0000-0001-000000040000',
    AGTP_RAPO_2_1_UserExemption = '00000000-0000-0000-0002-000000010000',
    AGTP_RAPO_2_2_PeriodExemption = '00000000-0000-0000-0002-000000020000',
    AGTP_RAPO_2_3_ValidTicket = '00000000-0000-0000-0002-000000030000',
    AGTP_RAPO_2_4_ValidETicket = '00000000-0000-0000-0002-000000040000',
    AGTP_RAPO_3_2_WrongAmount = '00000000-0000-0000-0003-000000020000',
    AGTP_RAPO_3_3_WrongDeduction = '00000000-0000-0000-0003-000000030000',
    AGTP_RAPO_3_4_WrongTicket = '00000000-0000-0000-0003-000000040000',
    AGTP_RAPO_4_1_InvalidFps = '00000000-0000-0000-0004-000000010000',
    AGTP_RAPO_4_2_ValidPreviousFps = '00000000-0000-0000-0004-000000020000',
    AGTP_RAPO_4_3_InvalidPreviousFps = '00000000-0000-0000-0004-000000030000',
}
