import { Component } from '@angular/core';
import { BladeService } from '../blade/blade.service';

@Component({
    selector: 'app-breadcrumbs',
    templateUrl: './breadcrumbs.component.html',
    styleUrls: ['./breadcrumbs.component.scss']
})
export class BreadcrumbsComponent {

    public blades;

    constructor(public bladeService: BladeService) {
        this.blades = bladeService.bladeComponentRef;
    }

    navigate(bladeIndex) {
        this.bladeService.closeToIndex(bladeIndex);
    }

}
