import { Injectable } from '@angular/core';
import { Observable, Observer, } from 'rxjs';
import { BladeConfig } from '../../../core/blade/blade-config';
import { Language } from './language.model';
import { LanguageCode } from '../shared/languagecode.model';

@Injectable({
    providedIn: 'root'
})

export class LanguagesService {
    constructor(
    ) { }
    // globale variabele om te kunnen editen
    languages =
        [
            new Language('1', 'Dutch', 'nl-BE', false),
            new Language('2', 'Dutch (Netherlands) ', 'nl-NL', false),
            new Language('3', 'French', 'fr-FR', false),
            new Language('4', 'French (Belgium)', 'fr-BE', false),
            new Language('5', 'German', 'de-DE', false),
            new Language('6', 'English (US)', 'en-US', false),
        ];

    getBladeConfig(): BladeConfig {
        return new BladeConfig(6, 'Languages');
    }
    getBladeConfigDetail(name: string, data: any): BladeConfig {
        return new BladeConfig(3, name, data);
    }

    // gets the languages (now static data)
    getLanguages(): Observable<Language[]> {
        return new Observable((observer: Observer<Language[]>) => {
            observer.next(this.languages);
            observer.complete();
        });

    }

    getLanguage(id: string): Observable<Language> {

        return new Observable((observer: Observer<Language>) => {
            let language: Language;
            if (id === '0') {
                language = new Language('0', '', '', false);
                observer.next(language);
                observer.complete();
            } else {
                this.getLanguages().subscribe((result: Language[]) => {
                    language = result.find((lang) => {
                        return lang.id === id;
                    });
                    observer.next(language);
                    observer.complete();
                });
            }
        });
    }

    getLangcodes(): Observable<LanguageCode[]> {
        return new Observable((observer: Observer<LanguageCode[]>) => {
            const lang = [
                new LanguageCode('1', 'nl-BE'),
                new LanguageCode('2', 'nl-NL'),
                new LanguageCode('3', 'fr-FR'),
                new LanguageCode('4', 'fr-BE'),
                new LanguageCode('5', 'de-DE'),
                new LanguageCode('6', 'en-US'),
                new LanguageCode('7', 'en-CA'),
                new LanguageCode('8', 'en-AU'),
                new LanguageCode('9', 'it-IT'),
                new LanguageCode('10', 'es-ES'),
            ];
            observer.next(lang);
            observer.complete();
        });
    }

    saveLanguage(newLanguage: Language): Observable<void> {
        return new Observable((observer) => {
            if (newLanguage.id === '0') {
                // create
                newLanguage.id = (Number(this.languages[this.languages.length - 1].id) + 1).toString();
                this.languages.push(newLanguage);
            } else {
                // edit
                const langindex = this.languages.indexOf(this.languages.find(o => o.id === newLanguage.id));
                this.languages[langindex] = newLanguage;
            }
            observer.next();
            observer.complete();
        });
    }

    removeLanguage(languageId: string): Observable<void> {
        return new Observable((observer) => {
            const langindex = this.languages.indexOf(this.languages.find(o => o.id === languageId));
            this.languages.splice(langindex, 1);
            observer.next();
            observer.complete();
        });
    }
}
