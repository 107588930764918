export class Language {
    public id: string;
    public name: string;
    public code: string;
    public baseID: string;
    public copytoalllanguages: boolean;  // not sure what this is

    constructor(id: string, name: string, code: string, copytoalllanguages: boolean) {
        this.id = id;
        this.name = name;
        this.code = code;
        this.copytoalllanguages = copytoalllanguages;
    }

}
