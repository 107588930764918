// used when creating a blade to give some data to the child
export class BladeConfig {
    index: number;
    columns: number;
    title: string;
    data?: any;
    constructor(columns: number, title: string, data?: any) {
        this.columns = columns;
        this.title = title;
        this.data = data;
    }
}
