import { Component, Type, OnDestroy, AfterViewInit, ComponentFactoryResolver, ComponentRef, ViewChild, ChangeDetectorRef } from '@angular/core';
import { InsertionDirective } from './insertion.directive';

@Component({
    selector: 'app-blade',
    templateUrl: './blade.component.html',
    styleUrls: ['./blade.component.scss']
})
export class BladeComponent implements AfterViewInit, OnDestroy {

    componentRef: ComponentRef<any>;
    childComponentType: Type<any>;
    title: string;
    index: number;

    @ViewChild(InsertionDirective, { static: false })
    insertionPoint: InsertionDirective;

    constructor(
        private componentFactoryResolver: ComponentFactoryResolver,
        private cd: ChangeDetectorRef
    ) { }

    ngAfterViewInit() {
        this.loadChildComponent(this.childComponentType);
        this.cd.detectChanges(); // need to detect changes, otherwise we'll have an ExpressionChangedAfterItHasBeenCheckedError
    }

    // destroy the component to free up memory & resources
    ngOnDestroy() {
        if (this.componentRef) {
            this.componentRef.destroy();
        }
    }

    loadChildComponent(componentType: Type<any>) {
        const componentFactory = this.componentFactoryResolver.resolveComponentFactory(componentType);

        const viewContainerRef = this.insertionPoint.viewContainerRef;
        viewContainerRef.clear();

        this.componentRef = viewContainerRef.createComponent(componentFactory);
    }
}
