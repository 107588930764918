import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

const routes: Routes = [
    // { path: '', component: ManagementSidebarComponent, pathMatch: 'full' },
    // { path: 'languages', component: LanguagesComponent },
    // { path: 'fetch-data', component: FetchDataComponent }
];

@NgModule({
    imports: [RouterModule.forRoot(routes)],
    exports: [RouterModule]
})
export class AppRoutingModule { }
