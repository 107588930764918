import { Component, OnInit, ViewChild } from '@angular/core';
import { DxDataGridComponent } from 'devextreme-angular';
import CustomStore from 'devextreme/data/custom_store';
import { BladeConfig } from '../../core/blade/blade-config';
import { BladeService } from '../../core/blade/blade.service';
import { BladeRef } from '../../core/blade/blade-ref';
import { TemplateService } from 'src/app/templates/shared/template.service';
import { ManagementService } from '../../management/shared/management.service';
import { UserService } from '../../shared/user/user.service';
import { ComplaintsDetailComponent } from '../../complaints/detail/complaints-detail.component';
import { TemplateInGrid } from 'src/app/templates/shared/template-in-grid.model';

import * as $ from 'jquery';
import { TranslationService } from '../../shared/translation/translation.service';

@Component({
    selector: 'app-templates-overview',
    templateUrl: './templates-overview.component.html',
    styleUrls: ['./templates-overview.component.scss']
})
export class TemplatesOverviewComponent implements OnInit {
    @ViewChild('grid', { static: true }) grid: DxDataGridComponent;

    constructor(
        private config: BladeConfig,
        private blade: BladeService,
        private bladeRef: BladeRef,

        private templateService: TemplateService,
        private managementService: ManagementService,
        private userService: UserService,
        private t: TranslationService,
    ) { }


    departmentDataSource: any;
    gridDataSource: any;

    searchValue: string;
    depfilterValue: string[];
    get departmentFilterValue(): string[] {
        return this.depfilterValue;
    }

    set departmentFilterValue(value: string[]) {
        this.depfilterValue = value || [];
    }

    gridColumns = [ // TODO: translations
        { dataField: 'Name', caption: this.t.get('Subject'), dataType: 'string' },
        { dataField: 'LastModified', caption: this.t.get('Date'), dataType: 'datetime', format: this.userService.getDateTimeFormat(), },
        {
            dataField: 'DepartmentId', caption: this.t.get('City'), dataType: 'string', customizeText: (data) => {
                if (data.value === undefined) return '';
                return this.departmentDataSource.__rawData.find(x => x.Id === data.value).Name;
            }
        }
    ];

    departmentColumns = [
        { dataField: 'Name', caption: this.t.get('Name'), dataType: 'string' }
    ];

    ngOnInit() {
        const self = this;
        this.departmentDataSource = new CustomStore({
            loadMode: 'raw',
            key: 'Id',
            load() {
                return self.managementService.getDepartments().toPromise();
            }
        });

        this.gridDataSource = new CustomStore({
            load(loadOptions: any) {

                if (!self.departmentFilterValue || self.departmentFilterValue.length === 0) {
                    return [];
                }

                let cleanSearchValue = self.searchValue;
                if (!cleanSearchValue) {
                    cleanSearchValue = '';
                }
                return self.templateService.getReminderOverview(self.departmentFilterValue, cleanSearchValue)
                    .toPromise().then(
                        (result: TemplateInGrid[]) => {
                            return {
                                data: result
                            };
                        });
            }
        });

    }

    close() {
        this.bladeRef.dismiss();
    }

    filterChanged() {
        // close all further blades
        this.blade.closeToIndex(this.config.index);
        this.refreshGrid();
    }

    refreshGrid() {
        // refresh the grid with the new data
        this.grid.instance.refresh();
    }

    // Used for calculating the height of the grid
    gridInitialized(e) {
        const filterHeight = $(e.element).parentsUntil('.content').parent().find('.filters').outerHeight(true);
        // calc --> 100% - height of the filter - margin top of the grid
        $(e.element).parentsUntil('.content').parent().find('.grid').css('height', `calc(100% - ${filterHeight}px)`);
    }

    onAfterValueChange(value) {
        this.searchValue = value;
        this.filterChanged();
    }

    gridValueChanged(e) {
        // == 0 when you clear a filter
        if (e.currentSelectedRowKeys.length === 0) {
            return;
        }

        const template: TemplateInGrid = e.currentSelectedRowKeys[0];

        const blade = this.blade.open(this.config.index, ComplaintsDetailComponent, this.templateService.getBladeConfigDetail(template.name, template));

        blade.result.subscribe(
            () => {
                // deselect the current selection so that you can open the details of the previous selected instance
                this.deselectAll();
                // refresh the grid so the changes are seen
                this.filterChanged();
            },
            () => {
                // dismissed
                this.deselectAll();
            });
    }

    deselectAll() {
        const keys = this.grid.instance.getSelectedRowKeys();
        this.grid.instance.deselectRows(keys);
    }

}
