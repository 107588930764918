import { Injectable } from '@angular/core';
import { Observable, from, Observer } from 'rxjs';
import { EnvironmentService } from '../../shared/environment/environment.service';
import { Token } from './token.model';
import * as $ from 'jquery';
import { TranslationService } from '../../shared/translation/translation.service';

@Injectable({
    providedIn: 'root'
})

export class AuthService {

    constructor(
        private environmentService: EnvironmentService,
        private translationService: TranslationService,
    ) { }

    private token: Token; // use as singleton
    private username: string; // use as singleton

    init(): Promise<any> {

        const self = this;
        this.environmentService.init();

        const observable = new Observable((observer: Observer<string>) => {

            // Check if there is a token in session storage
            const encodedToken = sessionStorage.getItem('token');

            const url = new URL(window.location.href);
            const t = url.searchParams.get('token');
            let r = url.searchParams.get('returnUrl');
            if (!r) {
                r = '';
            }

            if (!encodedToken) {
                // check if we have a token in the url (= return from identity server)
                if (t) {
                    // token is in url so store the token and reload the page with the return url
                    sessionStorage.setItem('token', t);
                    window.location.href = decodeURIComponent(r);
                    observer.error('');
                    observer.complete();
                    return;
                }
                // redirect to identity server with current url as return url
                const returnUrl = window.location.href;
                window.location.href = `${this.environmentService.identityUrl}en-US/Account/Login/v2?ReturnUrl=${encodeURIComponent(returnUrl)}`;
                observer.error('');
                observer.complete();
                return;
            }

            if (t) {
                // there is a token in session, but there is a token in the url so we are redirected again from the portal v1
                window.location.href = decodeURIComponent(r);
                observer.error('');
                observer.complete();
                return;
            }

            const accessToken = sessionStorage.getItem('access_token');

            if (!accessToken) {
                $.ajax({
                    type: 'POST',
                    url: `${this.environmentService.identityUrl}api/users/token/info`,
                    data: {
                        token: encodedToken
                    },
                    success(result) {
                        sessionStorage.setItem('access_token', result.AccessToken);
                        sessionStorage.setItem('username', result.Username);
                        sessionStorage.setItem('permissions', result.Permissions);
                        self.initTranslations(observer);
                        return;
                    }
                });
            } else {
                self.initTranslations(observer);
                return;
            }

        });

        return observable.toPromise();

    }

    initTranslations(observer: Observer<string>) {
        this.translationService.initTranslations().subscribe(
            () => {
                observer.next('');
                observer.complete();
            }
        );
    }

    public getTokens(): Observable<Token> {
        return from(this.getAccessToken());
    }

    public getUsername(): string {
        if (this.username) {
            return this.username;
        }

        const username = sessionStorage.getItem('username');
        this.username = username;
        return username;
    }

    public deleteTokens() {
        sessionStorage.removeItem('token');
        sessionStorage.removeItem('access_token');
    }

    private getAccessToken(): Promise<Token> {

        const self = this;
        return new Promise((resolve, reject) => {

            // check if token is filled in from mememory (spa)
            if (this.token) {
                resolve(this.token);
                return;
            }

            // initial page load, check if access token is in session storage
            const accessToken = sessionStorage.getItem('access_token');

            if (accessToken) {
                this.token = new Token(accessToken);
                resolve(new Token(accessToken));
            } else {
                // request new access token to the identity server
                const encodedToken = sessionStorage.getItem('token');

                $.ajax({
                    url: `${this.environmentService.identityUrl}/api/users/token/${encodedToken}`, success(result) {
                        self.token = new Token(result);
                        sessionStorage.setItem('access_token', result);
                        resolve(new Token(result));
                    }
                });
            }
        });
    }
}
