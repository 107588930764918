import { HttpEvent, HttpInterceptor, HttpHandler, HttpRequest, HttpResponse, HttpErrorResponse } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { retry, catchError } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { ErrorService } from './error.service';

@Injectable()
export class HttpErrorInterceptor implements HttpInterceptor {

    constructor(
        private errorService: ErrorService,
    ) { }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        return next.handle(request)
            .pipe(
                catchError((error: HttpErrorResponse) => {
                    let errorMessage = '';
                    if (error.error instanceof ErrorEvent) {
                        // client-side error
                        errorMessage = `Error: ${error.error.message}`;
                    } else {
                        // server-side error
                        switch (error.status) {
                            case 400:
                                break;
                            case 404:
                                errorMessage = '404 - The requested resource could not be found';
                                break;
                            default:
                                errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
                        }
                    }
                    if (errorMessage !== '') {
                        this.errorService.error.next(errorMessage);
                    }
                    return throwError(error);
                })
            );
    }
}
