import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BladeComponent } from './blade.component';
import { InsertionDirective } from './insertion.directive';

@NgModule({
    declarations: [
        BladeComponent,
        InsertionDirective,
    ],
    imports: [
        CommonModule,
    ],
    entryComponents: [
        BladeComponent,
    ],
})
export class BladeModule { }
