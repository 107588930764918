import { Component, OnInit } from '@angular/core';
import { AuthService } from '../core/auth/auth.service';
import { EnvironmentService } from '../shared/environment/environment.service';
import { TranslationService } from '../shared/translation/translation.service';



@Component({
    selector: 'app-navbar',
    templateUrl: './navbar.component.html',
    styleUrls: ['./navbar.component.scss'],
})
export class NavbarComponent implements OnInit {



    constructor(
        private authService: AuthService,
        private environmentService: EnvironmentService,
        private translate: TranslationService,
    ) { }

    accountUrl = `${this.environmentService.portalUrl}account`;
    username = '';


    ngOnInit() {
        this.username = this.authService.getUsername();
    }


    logout() {
        this.authService.deleteTokens();
        window.location.href = `${this.environmentService.portalUrl}account/logout/v2`;
    }

}
