import { Injectable } from '@angular/core';
import { CookieService } from 'ngx-cookie-service';

@Injectable({
    providedIn: 'root'
})
export class UserService {

    constructor(
        private cookie: CookieService,
    ) { }

    private dateFormat = 'dd/MM/yyyy';
    private timeFormat = 'HH:mm';
    private dateTimeFormat: string;
    private languageCode: string;

    getDateFormat(): string {
        if (this.dateFormat) {
            return this.dateFormat;
        }

        // todo: get date format
        return this.dateFormat;
    }

    getTimeFormat(): string {
        if (this.timeFormat) {
            return this.timeFormat;
        }

        // todo: get time format
        return this.timeFormat;
    }

    getDateTimeFormat(): string {
        if (this.dateTimeFormat) {
            return this.dateTimeFormat;
        }

        this.dateTimeFormat = `${this.getDateFormat()} ${this.getTimeFormat()}`;
        return this.dateTimeFormat;
    }

    getLanguageCode() {
        if (this.languageCode) {
            return this.languageCode;
        }

        // try getting the language via the cookie
        const language = this.cookie.get('Language');

        if (!language) {
            // if no cookie is found, try getting the language from the browser
            const acceptedLanguages = ['fr-BE', 'fr-FR', 'nl-NL', 'nl-BE', 'en-US'];
            let browserLanguage = window.navigator.language;

            //transforms language to culture
            if (browserLanguage.length == 2) {
                switch (browserLanguage) {
                    case "fr":
                        browserLanguage = "fr-FR";
                        break;
                    case "nl":
                        browserLanguage = "nl-BE";
                        break;
                    case "en":
                        browserLanguage = "en-US";

                        break;
                }
            }

            if (acceptedLanguages.includes(browserLanguage)) {
                this.languageCode = browserLanguage;
            } else {
                // Browser language is not found, or is not supported, so use fallback
                this.languageCode = 'en-US';
            }

        } else {
            this.languageCode = language;
        }

        return this.languageCode;
    }
}
