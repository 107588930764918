import { Component, OnInit, ViewChild } from '@angular/core';
import { DxDataGridComponent } from 'devextreme-angular';
import CustomStore from 'devextreme/data/custom_store';
import ArrayStore from 'devextreme/data/array_store';
import { BladeConfig } from '../../core/blade/blade-config';
import { BladeService } from '../../core/blade/blade.service';
import { BladeRef } from '../../core/blade/blade-ref';
import { ComplaintService } from '../shared/complaint.service';
import { ManagementService } from '../../management/shared/management.service';
import { UserService } from '../../shared/user/user.service';
import { ComplaintState } from '../shared/complaint-state.model';
import { ComplaintType } from '../shared/complaint-type.model';
import { ComplaintSearch } from '../shared/complaint-search.model';
import { ComplaintInGrid } from '../shared/complaint-in-grid.model';
import { ComplaintsDetailComponent } from '../detail/complaints-detail.component';

import * as moment from 'moment';
import * as $ from 'jquery';
import { TranslationService } from '../../shared/translation/translation.service';

@Component({
    selector: 'app-complaints-overview',
    templateUrl: './complaints-overview.component.html',
    styleUrls: ['./complaints-overview.component.scss']
})
export class ComplaintsOverviewComponent implements OnInit {

    @ViewChild('grid', { static: true }) grid: DxDataGridComponent;

    constructor(
        private config: BladeConfig,
        private blade: BladeService,
        private bladeRef: BladeRef,

        private complaintService: ComplaintService,
        private managementService: ManagementService,
        private userService: UserService,
        private t: TranslationService,
    ) { }

    states: ComplaintState[] = [];
    types: ComplaintType[] = [];
    dateTimeFormat: string = this.userService.getDateTimeFormat();

    departmentDataSource: any;
    gridDataSource: any;
    stateDataSource: any;
    typeDataSource: any;

    searchValue: string;
    depfilterValue: string[];
    get departmentFilterValue(): string[] {
        return this.depfilterValue;
    }

    set departmentFilterValue(value: string[]) {
        this.depfilterValue = value || [];
    }
    stFilterValue : number[] = [1];
    get stateFilterValue(): number[] {
        return this.stFilterValue;
    }
    set stateFilterValue(value: number[]) {
        this.stFilterValue = value || [];
    }

    tpFilterValue : string[] = ['00000000-0000-0000-0001-000000020000' , '00000000-0000-0000-0001-000000030000'];
    get typeFilterValue(): string[] {
        return this.tpFilterValue;
    }
    set typeFilterValue(value: string[]) {
        this.tpFilterValue = value || [];
    }

    startDate: Date = null;
    endDate: Date = null;

    gridColumns = [
        { dataField: 'date', caption: this.t.get('Date'), dataType: 'datetime', format: this.userService.getDateTimeFormat(), },
        { dataField: 'subject', caption: this.t.get('Subject'), dataType: 'string', },
        { dataField: 'citationReference', caption: this.t.get('ReferenceNumber'), dataType: 'string', },
        {
            dataField: 'status', caption: this.t.get('ComplaintState'), dataType: 'string', customizeText: (data) => {
                if (data.value === undefined) return '';
                return this.states.find(x => x.value === data.value).state;
            }
        },
        {
            dataField: 'citationStatus', caption: this.t.get('CitationState'), dataType: 'string', customizeText: (data) => {
                if (data.value === undefined) return '';
                return this.t.get('DocumentStatus_' + data.value);
            }
        },
        { dataField: 'citationCreationDate', caption: this.t.get('CreationDate'), dataType: 'datetime', format: this.userService.getDateTimeFormat(), },
        {
            dataField: 'departmentId', caption: this.t.get('City'), dataType: 'string', customizeText: (data) => {
                if (data.value === undefined) return '';
                return this.departmentDataSource.__rawData.find(x => x.Id === data.value).Name;
            }
        },
    ];

    stateColumns = [
        { dataField: 'state', caption: this.t.get('ComplaintState'), dataType: 'string' }
    ];

    typeColumns = [
        { dataField: 'text', caption: this.t.get('ComplaintType'), dataType: 'string' }
    ];

    departmentColumns = [
        { dataField: 'Name', caption: this.t.get('Name'), dataType: 'string' }
    ];

    ngOnInit() {
        const self = this;
        this.states = this.complaintService.getStates();
        this.types = this.complaintService.getTypes();
        this.departmentDataSource = new CustomStore({
            loadMode: 'raw',
            key: 'Id',
            load() {
                return self.managementService.getDepartments().toPromise();
            }
        });

        this.gridDataSource = new CustomStore({
            load(loadOptions: any) {

                if (!self.departmentFilterValue || self.departmentFilterValue.length === 0 ||
                    !self.stateFilterValue || self.stateFilterValue.length === 0 ||
                    !self.typeFilterValue || self.typeFilterValue.length === 0) {
                    return [];
                }

                const search = new ComplaintSearch();
                search.states = self.stateFilterValue;
                search.complaintTypes = self.typeFilterValue;
                search.searchValue = self.searchValue;

                if (self.startDate !== null) {
                    search.dateFrom = moment(self.startDate).utc().toDate();
                }
                if (self.endDate !== null) {
                    search.dateTo = moment(self.endDate).utc().toDate();
                }

                return self.complaintService.getByDepartments(self.departmentFilterValue, search).toPromise().then(
                    (result: ComplaintInGrid[]) => {
                        return {
                            data: result
                        };
                    });
            }
        });
        this.stateDataSource = new ArrayStore({
            key: 'value',
            data: self.states
        });
        this.typeDataSource = new ArrayStore({
            key: 'id',
            data: self.types
        });

    }

    close() {
        this.bladeRef.dismiss();
    }

    filterChanged() {
        // close all further blades
        this.blade.closeToIndex(this.config.index);
        this.refreshGrid();
    }

    refreshGrid() {
        // refresh the grid with the new data
        this.grid.instance.refresh();
    }

    startDateChanged(v) {
        this.startDate = v.value;
        this.filterChanged();
    }

    endDateChanged(v) {
        this.endDate = v.value;
        this.filterChanged();
    }

    // Used for calculating the height of the grid
    gridInitialized(e) {
        const filterHeight = $(e.element).parentsUntil('.content').parent().find('.filters').outerHeight(true);
        // calc --> 100% - height of the filter - margin top of the grid
        $(e.element).parentsUntil('.content').parent().find('.grid').css('height', `calc(100% - ${filterHeight}px)`);
    }

    onAfterValueChange(value) {
        this.searchValue = value;
        this.filterChanged();
    }

    gridValueChanged(e) {
        // == 0 when you clear a filter
        if (e.currentSelectedRowKeys.length === 0) {
            return;
        }

        const complaint: ComplaintInGrid = e.currentSelectedRowKeys[0];

        const blade = this.blade.open(this.config.index, ComplaintsDetailComponent, this.complaintService.getBladeConfigDetail(complaint.subject, complaint));

        blade.result.subscribe(
            () => {
                // deselect the current selection so that you can open the details of the previous selected instance
                this.deselectAll();
                // refresh the grid so the changes are seen
                this.filterChanged();
            },
            () => {
                // dismissed
                this.deselectAll();
            });
    }

    deselectAll() {
        const keys = this.grid.instance.getSelectedRowKeys();
        this.grid.instance.deselectRows(keys);
    }
}
